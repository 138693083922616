import React from 'react';
import { ActiveUsersChart, SessionsByDateChart, SessionsGeoChart, AnalyticsDashboard } from 'react-analytics-charts';

const GoogleAnalyticsDashboard = () => {
  debugger;
  return (
    <AnalyticsDashboard
      authOptions={{
        clientId: '893508487577-56qfega286ebtalkq71kmhvi1nskcfho.apps.googleusercontent.com',
      }}
      renderCharts={(gapi, viewId) => {
        return (
          <div>
            <SessionsByDateChart gapi={gapi} viewId={viewId} showPageViews showUsers />
            <SessionsGeoChart gapi={gapi} viewId={viewId} showPageViews />
            ... More charts here ...
          </div>
        );
      }}
    />
  );
};

export default GoogleAnalyticsDashboard;
