import { loginFailure, loginStart, loginSuccess } from '../redux/userRedux';
import { publicRequest } from '../requestMethod';

export const login = async (dispatch, user, setInvalidLogin) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post('Authenticate/login', user);
    dispatch(loginSuccess(res.data));
    localStorage.setItem('token', res.data.token);
    // window.location.reload();
  } catch (err) {
    dispatch(loginFailure());
    setInvalidLogin(true);
  }
};
export function logout() {
  return (dispatch) => {
    localStorage.removeItem('token');
  };
}
