import axios from 'axios';
const BASE_URL = 'https://easydukan.pk:446/api/';
const BASE_URLLOGIN = 'https://easydukan.pk:446/';

let TOKEN = null;
if (localStorage.getItem('token') != null) {
  TOKEN = localStorage.getItem('token');
}
///http://43.205.229.212:5000/index.html
export const publicRequest = axios.create({
  baseURL: BASE_URL,
});
export const FetchUrl = 'https://easydukan.pk:446/api/';
export const publicRequests = axios.create({
  baseURL: BASE_URLLOGIN,
});
export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: { token: `Bearer ${TOKEN}` },
});
